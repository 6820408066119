import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html'
})
export class DeliveryComponent implements OnInit {

  constructor() { }
  isTest = true;
  ngOnInit(): void {
  }
  
}
