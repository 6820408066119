import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IGenericResponse, Backend } from 'src/app/shared/models/GenericResponse'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient) { }

  private setAuth (){
    const udata = JSON.parse(localStorage.getItem('x-udata-rolo'));    
    if(udata!=null){
      this.httpOptions.headers =   this.httpOptions.headers.set('x-uname-rolo', udata.cuid);
    }
  }

  getTracking(orderId) {    
    const url = `${Backend.config().api_Url}/orders/trackDelivery/${orderId}`
    return this.http.get<IGenericResponse>(url, this.httpOptions);
  }

  acceptOrder(data): Observable<IGenericResponse>{
    this.setAuth();
    const url = `${Backend.config().api_Url}/orders/accept`
    return this.http.post<IGenericResponse>(url, data, this.httpOptions);
  }

  deliverOrder(data): Observable<IGenericResponse>{
    this.setAuth();
    const url = `${Backend.config().api_Url}/orders/check-delivery`
    return this.http.post<IGenericResponse>(url, data, this.httpOptions);
  }
 

  rejectOrder(data): Observable<IGenericResponse>{
    this.setAuth();
    const url = `${Backend.config().api_Url}/orders/reject`
    return this.http.post<IGenericResponse>(url, data, this.httpOptions);
  }

  reviewOrder(orderId): Observable<IGenericResponse>{
    this.setAuth();
    const url = `${Backend.config().api_Url}/orders/review/${orderId}`
    return this.http.get<IGenericResponse>(url, this.httpOptions);
  }

  reviewReserve(id): Observable<IGenericResponse>{
    this.setAuth();
    const url = `${Backend.config().api_Url}/reserve/review/${id}`
    return this.http.get<IGenericResponse>(url, this.httpOptions);
  }

  confirmReserve(data): Observable<IGenericResponse>{
    this.setAuth();
    const url = `${Backend.config().api_Url}/reserve/confirm`
    return this.http.post<IGenericResponse>(url, data, this.httpOptions);
  }

  rejectReserve(data): Observable<IGenericResponse>{
    this.setAuth();
    const url = `${Backend.config().api_Url}/reserve/reject`
    return this.http.post<IGenericResponse>(url, data, this.httpOptions);
  }

  deliverReserve(data): Observable<IGenericResponse>{
    this.setAuth();
    const url = `${Backend.config().api_Url}/reserve/check-delivery`
    return this.http.post<IGenericResponse>(url, data, this.httpOptions);
  }

}
